import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Image from 'react-bootstrap/Image';
import HeroSectionP from "./patnerPage-components/HeroSectionP";
import Channelpartner from "./patnerPage-components/Channelpartner";
import WhyPartnerwithAdiance from "./patnerPage-components/WhyPartnerwithAdiance";
import Globalnetwork from "./patnerPage-components/Globalnetwork";

const PartnersPage = () => {
  return (
    <>
      <div>
        <div>
          <Header />
        </div>
        <div>
          <HeroSectionP/>
          <Channelpartner/>
          <WhyPartnerwithAdiance />
          <Globalnetwork />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PartnersPage;
