import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Header from "./Header";
import Footer from "./Footer";
import NavHeader from "./NavHeader";
import { Helmet } from "react-helmet";
import ContactForm from "./ContactForm";
const products = [
  {
    title: "R Series",
    description:
      "Personalize this product series to fit your unique style and needs—endless customization options await!",
    imgUrl: "/images/CCTV_03.png",
  },
  {
    title: "H Series",
    description:
      "Personalize this product series to fit your unique style and needs—endless customization options await!",
    imgUrl: "/images/CCTV_02.png",
  },
  // Add more products as needed
];

const RSeries = ({ title }) => {
  const [activeTab, setActiveTab] = useState("benefits");

  // Find the product by title
  const product = products.find((product) => product.title === title);
  console.log("product : ",product);
  console.log("title : ",title);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#bf0603",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {product && (
        
        <div>
          
      <Helmet>
        <title>{product.title}</title>
        <meta name="description" content={product.description} />
      </Helmet>

      <Header />
      <div>
        {product && <NavHeader text={product.title} />}
          {/* Product Section */}
          <div style={{ margin: "0 10%" }}>

          
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "10% 0" }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {/* Product Image */}
              <img
                src={product.imgUrl}
                alt={product.title}
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  maxHeight: "400px",
                  borderRadius: "8px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {/* Product Title */}
              <Typography variant="h4" gutterBottom>
                {product.title}
              </Typography>
              {/* Product Description */}
              <Typography variant="body1" gutterBottom>
                {product.description}
              </Typography>
            </Grid>
          </Grid>
          <ContactForm/>
          </div>

        </div>
        </div>
        
      )}
      <Footer/>
    </ThemeProvider>
  );
};

export default RSeries;


