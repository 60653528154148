import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import NavHeader from "./NavHeader";
import Footer from "./Footer";
import { Typography, CardMedia, Chip } from "@mui/material";
import { Face, Label } from "@mui/icons-material"; // Import icons
import { Helmet } from "react-helmet";

const Blog1 = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (!id) return; // Prevent making API request if id is undefined

    const fetchBlog = async () => {
      try {
        const response = await fetch(
          `https://backend.adiance.com:443/api/blogs/getBlog/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch blog");
        }
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  // Function to render bold words in the paragraph
  // Function to render bold words in the paragraph
  const renderBoldWords = (text) => {
    // Regular expression to match words enclosed in ** **
    const boldRegex = /\*\*(.*?)\*\*/g;
    return text.split(boldRegex).map((chunk, index) => {
      if (index % 2 === 0) {
        // If the index is even, render normal text
        return chunk.split("\n").map((line, idx) => (
          <React.Fragment key={idx}>
            {line}
            <br /> {/* Add line break after each line */}
          </React.Fragment>
        ));
      } else {
        // If the index is odd, render bolded text
        return (
          <strong key={index} style={{ fontWeight: "bold" }}>
            {chunk}
          </strong>
        );
      }
    });
  };

  return (
    <div>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.description} />
      </Helmet>
      <Header />
      <NavHeader text={blog.title} />
      <div style={{ margin: "5% 10%" }}>
        <Typography
          variant="h5"
          gutterBottom
          marginBottom="2.5%"
          fontWeight={"bold"}
        >
          {blog.title}
        </Typography>
        <Typography variant="body1" paragraph>
          {blog.description.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br /> {/* Add line break after each line of the description */}
            </React.Fragment>
          ))}
        </Typography>
        <CardMedia
          component="img"
          src={`https://backend.adiance.com:443/images/${blog.image}`}
          alt={blog.title}
          sx={{ maxWidth: "80%", height: "auto", margin: "5% 10%" }}
        />
        {blog.sections.map((section, index) => (
          <div key={index}>
            <Typography variant="h6" gutterBottom fontWeight={"bold"}>
              {section.heading}
            </Typography>
            <Typography variant="body1" paragraph>
              {/* Render bold words in the section para */}
              {renderBoldWords(section.para).map((chunk, index) => (
                <React.Fragment key={index}>{chunk}</React.Fragment>
              ))}
            </Typography>
            {/* Render line break if provided in the paragraph text */}
            {section.para.includes("<br />") && <br />}
          </div>
        ))}
        <Typography variant="h6" gutterBottom fontWeight={"bold"}>
          Tags:
        </Typography>
        <div style={{ display: "flex", gap: 10 }}>
          {blog.tags.map((tag, index) => (
            <Chip
              key={index}
              icon={<Label />} // Use Label icon for tags
              label={tag}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog1;
