import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

const AddBlogs = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [sections, setSections] = useState([{ heading: "", para: "" }]);
  const [tags, setTags] = useState([]);
  const [uniqueFileName, setUniqueFileName] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);
  const inputRef = useRef(null); // Create a ref for the file input
  const navigate = useNavigate();
  const location = useLocation();
  const otpVerified = location.state?.otpVerified || false;

  const handleSectionChange = (index, field, value) => {
    const newSections = [...sections];
    newSections[index][field] = value;
    setSections(newSections);
  };

  const addSection = () => {
    setSections([...sections, { heading: "", para: "" }]);
  };

  const removeSection = (index) => {
    const newSections = [...sections];
    newSections.splice(index, 1);
    setSections(newSections);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const timestamp = Date.now();
    const fileName = `${timestamp}-${file.name}`;
    setUniqueFileName(fileName);
  };

  const handleUpload = async () => {
    if (!uniqueFileName) {
      console.error("Unique filename is not set.");
      return;
    }
    const formData = new FormData();
    formData.append("image", image, uniqueFileName);

    try {
      const response = await fetch("https://backend.adiance.com:443/upload", {
        // const response = await fetch("http://localhost:8007/upload", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image.");
      }

      console.log("Image uploaded successfully");
      setImageUploaded(true); // Set imageUploaded to true after successful upload
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (
        !title ||
        !description ||
        !image ||
        sections.some((section) => !section.heading || !section.para) ||
        !tags.length
      ) {
        throw new Error("Please fill in all required fields.");
      }

      const data = {
        title,
        description,
        image: uniqueFileName,
        tags,
        sections,
      };

      const response = await fetch(
        "https://backend.adiance.com:443/api/blogs/createBlog",
        // "http://localhost:8007/api/blogs/createBlog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit blog. Please try again later.");
      }

      alert("Blog submitted successfully"); // Display alert message

      setTitle("");
      setDescription("");
      setImage(null);
      setSections([{ heading: "", para: "" }]);
      setTags([]);
      setImageUploaded(false);

      // Clear the file input value
      inputRef.current.value = null;
    } catch (error) {
      console.error("Error:", error);
      alert(error.message || "Failed to submit blog. Please try again later."); // Display alert message
    }
  };

  const handleViewBlogsClick = () => {
    // Set otpVerified to true (or a value based on your verification logic)
    const otpVerified = true; // Replace with your logic to determine verification status

    navigate("/view-blogs", { state: { otpVerified } }); // Pass otpVerified as state
  };

  if (!otpVerified) {
    return (
      <Typography variant="body1">
        Please verify OTP to view blogs.
        <Link to="/Otp-Generator">Click here</Link>
      </Typography>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: "20px", marginTop: "50px" }}>
        <Typography variant="h5" align="center" gutterBottom>
          Add New Blog
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                margin="normal"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                inputProps={{
                  style: { whiteSpace: "pre-line" }, // Allow line breaks to be displayed
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <input
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
              >
                Upload Image
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ color: imageUploaded ? "green" : "black" }}
              >
                {imageUploaded
                  ? "Image uploaded successfully"
                  : "Please Upload Image and then the submit will be enabled"}
              </Typography>
            </Grid>
            {sections.map((section, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <TextField
                    label={`Section ${index + 1} - Heading`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={section.heading}
                    onChange={(e) =>
                      handleSectionChange(index, "heading", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={`Section ${index + 1} - Paragraph`}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    value={section.para}
                    onChange={(e) =>
                      handleSectionChange(index, "para", e.target.value)
                    }
                    required
                    inputProps={{
                      style: { whiteSpace: "pre-line" }, // Allow line breaks to be displayed
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => removeSection(index)}
                  >
                    Remove Section
                  </Button>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button variant="outlined" color="primary" onClick={addSection}>
                Add Section
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Tags (Comma separated)"
                variant="outlined"
                fullWidth
                margin="normal"
                value={tags.join(",")}
                onChange={(e) => setTags(e.target.value.split(","))}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!imageUploaded} // Disable the button if imageUploaded is false
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleViewBlogsClick}
              >
                View Blogs
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default AddBlogs;
